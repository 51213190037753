import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

const ToggleButton = ({
  title,
  toogleName,
  register,
  errors,
  personalCompleted,
  setPersonalCompleted,
}) => {
  return (
    <div>
      <label className="inline-flex items-center cursor-pointer">
        <input
          name={toogleName}
          {...register(toogleName, { required: true })}
          type="checkbox"
          value=""
          className="sr-only peer"
          checked={personalCompleted}
          onChange={() => {
            setPersonalCompleted(!personalCompleted);
          }}
        />
        <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
        <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
          {title}
        </span>
      </label>
      {errors[toogleName]?.type === "required" && (
        <p
          className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
          role="alert"
        >
          {`${title} is required`}
        </p>
      )}
    </div>
  );
};

export default ToggleButton;
